import React from 'react';
import { NextPage } from 'next';

import { Link, useTranslation } from '../i18n';

import { StaticPageLayout } from '../layouts/static-page.layout';

import { Page, H1 } from '../components/page';
import { Layout, Card } from '../components/element';
import { Image } from '../components/image';

const Error: NextPage = () => {
  const [t] = useTranslation('common');

  return (
    <StaticPageLayout wrapperClassName="wrapper">
      <Page>
        <Layout.Row gutter={20} type="flex" justify="center" className="mb-20">
          <Layout.Col span={24} sm={20} md={14} lg={12}>
            <Card className="is-always-shadow">
              <div className="text-center">
                <Image src="/static/pages/error/404.png" alt="" />
              </div>

              <H1>{t('errorPage.404.title')}</H1>
              <div>
                {t('errorPage.404.text')}{' '}
                <Link href="/">
                  <a>{t('errorPage.404.home')}</a>
                </Link>
                .
              </div>
            </Card>
          </Layout.Col>
        </Layout.Row>
      </Page>
    </StaticPageLayout>
  );
};

// noinspection JSUnusedGlobalSymbols
export default Error;
