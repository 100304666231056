import React from 'react';

import { useTranslation } from '../i18n';

import { AppLayout } from './app.layout';

import { H1, Page } from '../components/page';
import { Layout } from '../components/element';

export interface StaticPageLayoutProps {
  ns?: string;
  title?: string;
  text?: string;
  wrapperClassName?: string;
}

export const StaticPageLayout: React.FC<StaticPageLayoutProps> = ({
  children,
  ns,
  title,
  text,
  wrapperClassName = 'wrapper',
}) => {
  const [t] = useTranslation(ns);

  return (
    <AppLayout>
      <Page>
        <div className={wrapperClassName}>
          <Layout.Row gutter={20}>
            <Layout.Col span={24}>
              {title && <H1>{t(title)}</H1>}
              {/* eslint-disable-next-line react/no-danger */}
              {text && <div dangerouslySetInnerHTML={{ __html: t(text) }} />}
              {children}
            </Layout.Col>
          </Layout.Row>
        </div>
      </Page>
    </AppLayout>
  );
};
